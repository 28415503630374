body {
  margin: 0;
  background-color: #121212;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.RcButton {
  display: inline-block;
  margin-right: .2em;
  border-radius: 2px;
  padding: .25em 1em;
  user-select: none;
  background-color: cornflowerblue;
  color: white;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  background-position: center;
  transition: background 0.3s;
}

.RcButton:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.RcButton:active:not(.isDisabled) {
  background-color: #6eb9f7 !important;
  background-size: 100%;
  transition: background 0s;
}

.RcButton:hover {
  cursor: pointer;
}

.RcButton.isDisabled {
  background-color: grey !important;
}

.RcSaveButton {
  background-color: cornflowerblue;
}

.RcCancelButton {
  background-color: antiquewhite;
  color: #282c34;
}

input {
  padding: .2em;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #333;
}

  input:focus {
  outline: none;
  padding: .2em;
  font-size: 16px;
  border: 0;
  border-bottom: 2px solid cornflowerblue;
}