.page-ranking .timerResult {
    position: relative;
}
.page-ranking .timerResult .centillis:before {
    content: ' ';
}
.page-ranking .timerResult .centillis {
    font-size: 9px;
    position: absolute;
    top: 1px;
    margin-left: 1px;
}