.page-contestants table {
    width: 100%;
    border-collapse: collapse;
}

.page-contestants table thead th {
    text-align: left;
}

.page-contestants table thead th.sortable:hover {
    cursor: pointer;
}

.page-contestants table thead th .sortIcon {
    width: 20px;
}


.page-contestants .contestant td {
    padding: .25em;
    border-bottom: 1px solid #999999;
}

.page-contestants .contestant.isNotActive {
    opacity: 0.3;
}

.page-contestants .headStartNumber:before {
    content: '';
}


/*.page-contestants .contestant > div { padding: 0 .25em; }*/
.page-contestants .contestant .startNumber { text-align: center }
.page-contestants .contestant .participantCount { text-align: center }
/*.page-contestants .contestant name { flex: 0 0 50%; }*/
/*.page-contestants .contestant active { flex: 0 0 20px; }*/
/*.page-contestants .contestant position { flex: 0 0 40px; }*/
/*.page-contestants .contestant timerResult { flex: 0 0 80px; }*/
/*.page-contestants .contestant buttonWrapper { flex: 0 0 150px; }*/

.page-contestants .contestant .number {
    text-align: right;
}

.page-contestants .contestant .startNumber span {
    border-radius: 2px;
    padding: .1em .2em;
    color: white;
    background-color: mediumpurple;
    width: 2em;
}

.page-contestants .contestant .name input {
    width: 35vw;
}

.page-contestants .contestant .participantCount input {
    width: 40px;
    text-align: center;
}

.page-contestants .contestant div.timerResult {
    font-family: 'Major Mono Display', monospace;
    font-weight: bold;
}

.page-contestants .contestant div.buttonWrapper div {
    display: inline;
}



