.race {
    padding: 1em;
    background-color: #eee;
    border-bottom: 1px solid #999;
    font-size: 16px;
    text-align: left;
}

.race a {
    /*color: white;*/
}

